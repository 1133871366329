import React, { useContext } from "react";
import Image from "next/image";

import type { Article, ExternalImage } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getImageLayout } from "./ImageResource";
import { TeaserContext } from "../../../contexts/TeaserContext";
import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "668px", tablet: "100vw" });

const isExternalImageAMainResource = (
  article: Article,
  resource: ExternalImage
) => {
  const { main_resource } = article;
  if (!main_resource) return false;
  if (main_resource.type !== resource.type) return false;

  return main_resource.url === resource.url;
};

export const ExternalImageResource: ResourceComponent<ExternalImage> = ({
  resource,
  isOpen
}) => {
  const { article } = useContext(ArticleContext);
  const { teaserType } = useContext(TeaserContext);
  const isThisImageTheMain = isExternalImageAMainResource(article, resource);
  const layout = getImageLayout(undefined, teaserType, isOpen);
  const isSmall = layout === "small" && isThisImageTheMain;

  const caption = "";
  const query = resource?.subtype === "stock" && isSmall ? "&w=400&h=400" : "";

  const imageUrl = `${resource.url}${query}`;

  const img = (
    <Image
      className="resource-img"
      src={imageUrl}
      title={caption}
      alt={caption}
      sizes={sizes}
      width={0}
      height={0}
    />
  );

  const maybeZoom =
    article._expand || article._full ? (
      <span className="resource-zoomable">{img}</span>
    ) : (
      img
    );

  return (
    <div className={`resource resource--image resource--image--${layout}`}>
      {maybeZoom}
    </div>
  );
};
