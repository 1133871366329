import React from "react";

import type { ChevronDescription } from "../../../client/ContentApi/models/chevronDescription";
import type { ResourceComponent } from "./resourceToComponents";

import ArrowIcon from "../../../../public/img/svg/chevron-arrow.svg";

export const ChevronDescriptionResource: ResourceComponent<
  ChevronDescription
> = ({ resource, decoration }) => {
  const { text } = resource;
  return (
    <div className="resource--chevron" style={decoration}>
      <span>{text}</span>
      <ArrowIcon className="resource--chevron-arrow" />
    </div>
  );
};
