import React, { useContext } from "react";
import VisibilitySensor from "react-visibility-sensor";

import type { Article } from "../../../../client";

import { getDecorationProperties } from "../../../../client/helpers";
import { TeaserContext } from "../../../../contexts/TeaserContext";
import { LinkToArticle } from "../../../Link";
import { Teaser } from "../../Teasers";
import { TrackingContext } from "../../../../contexts/TrackingContext";

interface Props {
  article: Article;
}

export function CarouselItem({ article }: Props) {
  const isSponsored = article?.sponsor;
  const { trackViewedAdInFeed, teaserClick } = useContext(TrackingContext);

  return (
    <LinkToArticle
      article={article}
      className="article-link"
      onClickCapture={e => {
        teaserClick(article, 0, "");
        return;
      }}
    >
      <article
        style={getDecorationProperties(article?.newsfeed_decoration)}
        id={`tiny-${article.article_id}`}
        className={`article article--tiny ${
          article.sponsor ? "article--sponsored" : ""
        }`}
      >
        <VisibilitySensor
          scrollCheck={true}
          onChange={(isVisible: boolean) => {
            if (isVisible && isSponsored) {
              trackViewedAdInFeed(article, 0);
            }
          }}
        >
          <TeaserContext.Provider value={{ teaserType: "tiny" }}>
            <Teaser article={article} />
          </TeaserContext.Provider>
        </VisibilitySensor>
      </article>
    </LinkToArticle>
  );
}
