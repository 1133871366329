import React, { useContext } from "react";

import type { EvergreenArticleLink } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { useHidePremiumContent } from "../../../hooks/useHidePremiumContent";
import { LinkToArticleWithOnlyId } from "../../Link";
import { getGroupedStyle } from "../helpers";
import { getConfig } from "../../../config";

export const EvergreenArticleLinkResource: ResourceComponent<
  EvergreenArticleLink
> = ({ resource, group }) => {
  const { inArticleEvergreenClick } = useContext(TrackingContext);
  const { article } = useContext(ArticleContext);
  const { translation } = getConfig();
  const shouldBeHidden = useHidePremiumContent(resource);

  const { article_id, title, is_premium } = resource;
  const baseClass = "resource--evergreen_article_link";
  const premiumClass = is_premium && `${baseClass}--premium`;

  const vignette = resource.vignette && (
    <span className={`${baseClass}-vignette`}>
      {resource.vignette.value}
      {resource.vignette.separator}
    </span>
  );

  const premiumBadge = is_premium && translation.premium_badge && (
    <div className={`${baseClass}-premiumbadge`}>
      {translation.premium_badge}
    </div>
  );

  if (shouldBeHidden) {
    return null;
  }

  return (
    <div
      className={`resource ${baseClass} ${premiumClass}
       ${getGroupedStyle(group)}`}
    >
      <LinkToArticleWithOnlyId
        articleId={article_id}
        title={title}
        className={`${baseClass}-link a a--primary`}
        target="_blank"
        onClick={() => {
          inArticleEvergreenClick(article, resource);
        }}
      >
        <span className={`${baseClass}-title`}>
          {vignette} {title}
        </span>
        {premiumBadge}
      </LinkToArticleWithOnlyId>
    </div>
  );
};
