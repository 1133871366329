import React, { useContext } from "react";

import type { Byline, BylineAuthors } from "../../../client/ContentApi/models";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getFormatPostfix } from "../helpers";
import { dmt } from "./helpers/date";

function getBylineText(author: BylineAuthors) {
  if (author.title && author.newsroom) {
    return `${author.title}, ${author.newsroom}`;
  }
  if (author.title) {
    return author.title;
  }
  if (author.newsroom) {
    return author.newsroom;
  }
  return "";
}

export const BylineAuthor = ({
  byline,
  author
}: {
  byline: Byline;
  author: BylineAuthors;
}) => {
  const { article } = useContext(ArticleContext);
  const email = author.email || byline.fallback_email;

  return (
    <a
      className="a a--primary"
      target="_blank"
      rel="noopener noreferrer"
      href={`
                mailto:${encodeURIComponent(
                  email
                )}?subject=${encodeURIComponent(article.title.value)}`}
    >
      {getBylineText(author)}
    </a>
  );
};

export const BylineResource: ResourceComponent<Byline> = ({
  resource: byline,
  article
}) => {
  const formatPostfix = getFormatPostfix(article?.format);

  return (
    <div
      className={`
        resource--byline 
        article-meta
        resource--byline${formatPostfix}
    `}
    >
      <div className="article-meta-author">
        <span className="article-meta-byline">
          Av{" "}
          {byline?.authors.map((author, i) => (
            <React.Fragment key={author.id}>
              <BylineAuthor byline={byline} author={author} />
              {i === byline.authors.length - 1 ? "" : ", "}
            </React.Fragment>
          ))}
        </span>
      </div>
      {article && (
        <>
          <div className="article-meta-published">
            Publicerad {dmt(article.changes.published)}
          </div>
          {article.changes.updated && (
            <div className="article-meta-updated">
              Uppdaterad {dmt(article.changes.updated)}
            </div>
          )}
        </>
      )}
    </div>
  );
};
