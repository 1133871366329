import React, { useContext } from "react";

import type { Gallery } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getGroupedStyle } from "../helpers";
import { computeImage } from "./helpers/computeImage";
import { GalleryButton } from "../../Modal/buttons/GalleryButton";

export const GalleryResource: ResourceComponent<Gallery> = ({
  resource,
  group
}) => {
  const { lazy } = useContext(ArticleContext);

  const images = resource.components;

  const computedImages = images.map(image => {
    return computeImage(image);
  });

  const groupedStyle = getGroupedStyle(group);
  const watermark = computedImages[0].watermark;
  const imageUrl = computedImages[0].imageUrl;

  return (
    <div
      className={`resource resource--image resource--image--large ${groupedStyle}`}
    >
      <GalleryButton
        images={images}
        watermark={watermark}
        imageUrl={imageUrl}
        lazy={lazy}
      />
    </div>
  );
};
