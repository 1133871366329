import React, { useContext, useState, useEffect } from "react";
import Image from "next/image";

import type { Answer, Text } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { imageSrc } from "../../../lib/image";
import { getFormatPostfix, getGroupedStyle } from "../helpers";
import { TextResource } from "./TextResource";
import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "125px", tablet: "18vw", mobile: "40vw" });

export const AnswerResource: ResourceComponent<Answer> = ({
  resource,
  group
}) => {
  const [isLastAnswer, setLastAnswer] = useState(false);
  const { article } = useContext(ArticleContext);

  const imgSrc = imageSrc(resource, "1x1", true);
  const idx = article.resources.findIndex(r => r === resource);

  useEffect(() => {
    if (article.resources[idx + 1]?.type !== "Answer") {
      setLastAnswer(true);
    }
  }, [isLastAnswer, setLastAnswer, article.resources, idx]);

  const formatPostfix = getFormatPostfix(article.format);

  return (
    <div
      className={`resource resource-answer resource-answer${formatPostfix} ${getGroupedStyle(
        group
      )}`}
    >
      <div className="resource resource-answer--card">
        <div
          className={`resource-answer-expert--container
        resource-answer-expert--container${formatPostfix}`}
        >
          <div className="resource-answer-expert-image">
            <Image
              className="resource-img"
              src={imgSrc}
              loading="lazy"
              alt="expert"
              sizes={sizes}
              width={0}
              height={0}
            />
          </div>
          <div className="resource-answer-expert--info">
            <div
              className={`resource-answer-expert--name resource-answer-expert--name${formatPostfix}`}
            >
              {resource.name}
            </div>
            <div
              className={`resource-answer-expert--title resource-answer-expert--title${formatPostfix}`}
            >
              {resource.title}
            </div>
          </div>
        </div>
        <div
          className={`resource-answer-expert--text ${
            isLastAnswer ? "resource-answer-expert--last" : ""
          }`}
        >
          <TextResource resource={resource as unknown as Text} />
        </div>
      </div>
    </div>
  );
};
