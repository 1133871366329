import React, { useCallback, useContext, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import type { Article, Carousel, Topic } from "../../../../client";
import type { ResourceComponent } from "../resourceToComponents";

import { ArticleContext } from "../../../../contexts/ArticleContext";
import { isArticle } from "../../../../helpers";
import { LinkToTopic } from "../../../Link";
import { StarBox } from "../../../StarBox";
import { NativeArticleAd } from "../../../Ad/components/NativeArticleAd";
import { CarouselItem } from "./CarouselItem";

const Heading = ({ story }: { story?: Topic }) => {
  if (!story) {
    return null;
  }
  return (
    <>
      <h2 className="resource--carousel-heading">
        <LinkToTopic topic={story}>{story.title}</LinkToTopic>
      </h2>
      <StarBox topic={story} />
    </>
  );
};

export const CarouselResource: ResourceComponent<Carousel> = ({
  resource,
  isExpanded
}) => {
  const { article, loadStoryArticles } = useContext(ArticleContext);
  const [storyArticles, setStoryArticles] = useState<Article[]>([]);

  const load = useCallback(
    async (isVisible: boolean) => {
      if (isVisible && storyArticles.length === 0) {
        if (loadStoryArticles) {
          const group = await loadStoryArticles();
          const articles = group.reduce<Article[]>((agg, item) => {
            const firstComponent = item[0];
            if (isArticle(firstComponent)) {
              agg.push(firstComponent);
            }
            return agg;
          }, []);
          setStoryArticles(articles);
        }
      }
    },
    [storyArticles.length, loadStoryArticles]
  );

  if (isExpanded) {
    return null;
  }

  return (
    <>
      <NativeArticleAd />
      <VisibilitySensor onChange={load} scrollCheck={true}>
        <section className="resource resource--carousel hidden-xs">
          <Heading story={article.story} />
          {storyArticles
            .filter(a => article.sponsor || !a.sponsor)
            .filter(a => a.article_id !== article.article_id)
            .slice(0, 3)
            .map(article => {
              return (
                <CarouselItem key={article.article_id} article={article} />
              );
            })}
        </section>
      </VisibilitySensor>
    </>
  );
};
