import React, { useCallback, useState } from "react";

import type { Fact } from "../../../client/ContentApi/models/fact";
import type { ResourceComponent } from "./resourceToComponents";
import type { Inscription } from "../../../client";

import { getGroupedStyle } from "../helpers";
import { Markup } from "./TextResource/Markup";
import { isBlockListOrdered, isBlockListUnordered } from "../../../client";

const renderList = (items: Array<Inscription>) =>
  items.map((item, i) => <li key={i}>{item.value}</li>);
const renderOrderedList = (items: Array<Inscription>, i: number) => (
  <ol className="resource--text-list" key={i}>
    {renderList(items)}
  </ol>
);
const renderUnorderedList = (items: Array<Inscription>, i: number) => (
  <ul className="resource--text-list" key={i}>
    {renderList(items)}
  </ul>
);

export const FactResource: ResourceComponent<Fact> = ({
  resource,
  group,
  decoration
}) => {
  const [isBordered, setIsBordered] = useState(false);

  // Add border when computed primary and secondary backgrounds are equal
  const decideIfBordered = useCallback((element: HTMLDivElement) => {
    const getStyleValue = (style: string) =>
      window && element
        ? window.getComputedStyle(element).getPropertyValue(style)
        : null;

    const bg_p = getStyleValue("--background_primary");
    const bg_s = getStyleValue("--background_secondary");
    setIsBordered(bg_p === bg_s && bg_p !== null);
  }, []);

  const handleRef = decideIfBordered;
  const borderedStyle = isBordered ? "resource--fact-container--bordered" : "";

  return (
    <div
      className={`resource--fact ${getGroupedStyle(group)}`}
      style={decoration}
      ref={handleRef}
    >
      <div className={`resource--fact-container ${borderedStyle}`}>
        <h2 className="resource--fact-title">{resource.title?.value}</h2>
        {resource.paragraphs?.map((fact, index) => {
          if (isBlockListOrdered(fact)) {
            return renderOrderedList(fact.items, index);
          } else if (isBlockListUnordered(fact)) {
            return renderUnorderedList(fact.items, index);
          } else {
            return (
              <div className="resource--fact-paragraph" key={index}>
                <Markup text={fact.text} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
