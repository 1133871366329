import React from "react";

import type { HorizontalDivider } from "../../../client/ContentApi/models/horizontalDivider";
import type { ResourceComponent } from "./resourceToComponents";

import { getGroupedStyle } from "../helpers";

export const HorizontalDividerResource: ResourceComponent<
  HorizontalDivider
> = ({ group }) => {
  return (
    <div className={`resource--horizontaldivider ${getGroupedStyle(group)}`}>
      <hr className="resource--horizontaldivider-line" />
    </div>
  );
};
